<template>
    <styled-interface
        hide-toolbar
        no-padding>
        <onboarding-header
            :dealer="currentDealer"
            step="Channel Setup">
            <template #actions>
                <action-button
                    icon="indicator-error"
                    position="left"
                    light
                    normal-text
                    @click="saveAndExit()">
                    Save &amp; Exit
                </action-button>
            </template>
        </onboarding-header>

        <div
            v-if="loading"
            class="fill-height layout align-center justify-center">
            <styled-loader
                size="25"
                label="Loading channels..." />
        </div>
        <div
            v-else-if="channels.length > 0"
            class="pt-5">
            <div class="container">
                <div class="layout align-center justify-center row">
                    <div class="flex">
                        <div class="ma-5">
                            <div class="my-4">
                                <h2>
                                    Channel Setup
                                </h2>
                            </div>
                            <div class="grid grid-cols-3 gap-4">
                                <div
                                    v-for="(channel, index) in channels"
                                    :key="index"
                                    class="">
                                    <styled-card
                                        hide-header
                                        centered
                                        simple
                                        class="pa-4 text-center height-100 relative"
                                        :class="[channelHasBBProduct(channel, currentDealer.products.data) ? 'disabled-overlay' : '']">
                                        <div class="relative">
                                            <div class="channel-content">
                                                <div class="layout align-center justify-start row">
                                                    <div>
                                                        <img
                                                            style="height: 50px;"
                                                            :src="`/img/platforms/${channel.icon_name}.svg`">
                                                    </div>
                                                    <h2 class="ml-3">
                                                        {{ fbToMeta(channel.name) }}
                                                    </h2>
                                                </div>
                                                <div class="mt-5">
                                                    {{ channel.description }}
                                                </div>
                                            </div>
                                            <div>
                                                <img
                                                    class="background-icon"
                                                    :src="`/img/platforms/${channel.icon_name}.svg`">
                                            </div>
                                            <div class="py-5" />
                                            <div class="py-3" />
                                        </div>
                                        <div class="pb-4 action-buttons">
                                            <styled-button
                                                v-if="channel.authStatus"
                                                target="_blank"
                                                class="px-5"
                                                @click="handleChannelSelection(channel)">
                                                SETUP
                                            </styled-button>
                                            <styled-button
                                                v-else
                                                class="px-5"
                                                @click="openAuthenticateModal(channel)">
                                                AUTHENTICATE AND SETUP
                                            </styled-button>
                                            <div
                                                v-if="channel.learn_more_link"
                                                class="pt-2">
                                                <a
                                                    :href="channel.learn_more_link"
                                                    target="_blank">
                                                    Learn More
                                                </a>
                                            </div>
                                        </div>
                                    </styled-card>
                                </div>
                                <platform-authentication-slide-out
                                    v-model="showAuthenticateSlideOut"
                                    :channel="authenticateModalData"
                                    :redirect-path="'/dealers/' + currentDealer.id + '/channel-setup/' + authenticateModalData.name" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </styled-interface>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import StyledLoader from '@/components/globals/StyledLoader';
    import StyledCard from '@/components/globals/StyledCard';
    import StyledButton from '@/components/globals/StyledButton';
    import StyledInterface from '@/components/globals/StyledInterface';
    import OnboardingHeader from '@/components/globals/OnboardingHeader';
    import ActionButton from '@/components/globals/ActionButton';
    import { getDealerChannels } from '@/components/onboarding/channel-setup/channels.js';
    import { channelHasBBProduct } from '@/components/onboarding/channel-setup/channels.js';
    import PlatformAuthenticationSlideOut from '@/components/resellers/settings/PlatformAuthenticationSlideOut';
    import { PLATFORM_FACEBOOK } from '@/helpers/globals';
    import { fbToMeta } from '@/helpers/fbToMeta';
    export default {
        name: 'ChannelSelection',
        title: 'Channel Selection',
        components: {
            StyledCard,
            StyledLoader,
            StyledButton,
            StyledInterface,
            OnboardingHeader,
            ActionButton,
            PlatformAuthenticationSlideOut
        },
        data() {
            return {
                loading: false,
                channels: {},
                showAuthenticateSlideOut: false,
                authenticateModalData: {
                    name: '',
                },
                fbToMeta
            };
        },
        computed: {
            ...mapState({
                currentDealer: (state) => state.dealer.currentDealer,
                currentAgency: (state) => state.agency.currentAgency
            }),
            ...mapGetters([
                'dealerHasVerticals'
            ])
        },
        async created() {
            this.loading = true;
            await this.waitForDealerLoaded();
            await this.checkPlatformAuthStatus();
            this.loading = false;
        },
        methods: {
            ...mapActions([
                'updateCurrentDealer',
                'waitForDealerLoaded'
            ]),
            async saveAndExit() {
                await this.updateCurrentDealer();
                this.$router.push({ name: 'facebook-dashboard' });
            },
            channelHasBBProduct,
            /**
             * Check if platform is authenticated or not
             */
            async checkPlatformAuthStatus() {
                // List of platforms that currently support omni authentication
                let allowedOmniAuthenticationChannels = ['snapchat', 'tiktok', 'pinterest'];

                const channels = getDealerChannels(this.currentDealer);

                this.channels = await Promise.all(channels.map(async(channel) => {
                    let status = true;
                    if (allowedOmniAuthenticationChannels.includes(channel.name.toLowerCase())) {
                        try {
                            await this.getPlatformAuthStatus(channel.name.toLowerCase()).then(response => {
                                if(response.data.token) {
                                    status = true;
                                } else {
                                    status = false;
                                }
                            });
                        } catch (error) {
                            status = false;
                        }
                    }

                    channel.authStatus = status;
                    return channel;
                }));

                // Remove this when facebook is moved over to omni authentication
                this.setFacebookAuthStatus();
            },
            getPlatformAuthStatus(platformName) {
                return this.$apiRepository.getPlatformAuthStatus(this.currentAgency.id, platformName);
            },
            openAuthenticateModal(data) {
                this.showAuthenticateSlideOut = true;
                this.authenticateModalData = data;
            },
            handleChannelSelection(channel) {
                this.$router.push({
                    name: 'channel-setup-product-selection',
                    params: {
                        dealer_id: this.currentDealer.id,
                        channel: channel.name
                    },
                    query: {
                        redirect_url: this.$route.query?.redirect_url ?? undefined
                    }
                });
            },
            setFacebookAuthStatus() {
                // Check if the agency does not have the facebook_business_id set
                if (!(this.currentAgency?.facebook_business_id)) {
                    this.channels = this.channels.map(channel => {
                        if (channel.key === PLATFORM_FACEBOOK) {
                            channel.authStatus = false;
                        }

                        return channel;
                    });
                }
            }
        },
    };
</script>

<style scoped>
.relative {
    position: relative;
}

.background-icon {
    position: absolute;
    height: 300px;
    width: 300px;
    opacity: 0.05;
    top: -50px;
    left: -100px;
}

.grid {
    display: grid;
}

.grid-cols-3 {
    grid-template-columns: repeat(3,minmax(0,1fr));
}

.gap-4 {
    gap: 4rem;
}

.text-orange {
    font-size: 16px;
    color: #E28D00;
}

.disabled-overlay {
    pointer-events: none;
    opacity: 0.35;
}

.height-100 {
    height: 100%;
}

.action-buttons {
    position: absolute;
    bottom:0px;
    width:100%;
}

.channel-content {
    position: relative;
    z-index: 10;
}
</style>
